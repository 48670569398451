import './select_checkbox'
import './required_checkbox'
import { cooperationTable } from './cooperation_price_list'

document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')

    for (const item of document.querySelectorAll('.page-navigation li a')) {
        const itemHref = item.getAttribute('href')
        if (typeof itemHref === 'string' && itemHref.includes('#link-hide')) {
            item.removeAttribute('href')
        }
    }


    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }


    for (const svg of document.querySelectorAll('.cooperation aside img')) {
        svg.insertAdjacentHTML('afterend', `<div class="info-tooltip" >${svg.alt}</div>`)
    }

    // Open according collapse
    for (const collapse of document.querySelectorAll('.collapse-box')) {
        if (collapse.querySelector('*[id*="open"')) {
            collapse.querySelector('header[id^=trigger-open]').classList.remove('collapsed')
            collapse.querySelector('header[id^=trigger-open]').setAttribute('aria-expanded', true)
            collapse.querySelector('aside[id^=open]').classList.add('collapse', 'show')
        }
    }

    for (const item of document.querySelectorAll('.prevent-default')) {
        item.href = ''
    }

    // Add subject for link with mailto
    for (const btn of document.querySelectorAll('.btn[href^="mailto"]')) {
        if (btn.dataset.subject) {
            btn.href = `${btn.href}?subject=${btn.dataset.subject}`
        }
    }

    // Change menu while scrolling
    const mainHeader = document.querySelector('.main-header')
    if (mainHeader) {
        window.addEventListener('scroll', () => {
            window.scrollY > 120
                ? mainHeader.classList.add('page-scroll')
                : mainHeader.classList.remove('page-scroll')
        })
    }

    cooperationTable()
})

addEventListener("resize", cooperationTable)
